import React from 'react'

function Register() {
  return (
    <div className="flex flex-col">
      <h1 className="text-center">Register</h1>
      <a
        href="/register/owner"
        onClick={(e) => {
          window.router.setView(window.router.views.get('registerOwner'))
          e.preventDefault()
          return false
        }}
        className="btn"
      >
        Owner
      </a>
      <a
        href="/register/operator"
        onClick={(e) => {
          window.router.setView(window.router.views.get('registerOperator'))
          e.preventDefault()
          return false
        }}
        className="btn"
      >
        Operator
      </a>
      <a href="/register/device" className="btn">
        Device
      </a>
    </div>
  )
}

export default Register
