import React from 'react'
import logo from './logo.png'
import origeen from './origeen.svg'

const navigate = (e) => {
  window.router.setView(window.router.views.get('home'))
  e.preventDefault()
  return false
}

export default () => (
  <a
    className="flex items-center pl-12 mt-12 mb-16 print:hidden"
    href="/"
    onClick={navigate}
    target="_self">
    <img className="h-16" src={logo} alt="" />
    <img className="h-5 ml-4" src={origeen} alt="" />
  </a>
)
