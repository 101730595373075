import React, { useState } from 'react'
import { Auth } from 'aws-amplify'

async function confirmSignUp(username, code) {
  try {
    await Auth.confirmSignUp(username, code)
    window.router.setView(window.router.views.get('registerOwnerVerified'))
  } catch (error) {
    window.alert(error.message)
  }
}

export default () => {
  const [verifyCode, setVerifyCode] = useState('')

  const inputChange = (e) => setVerifyCode(e.target.value)

  const verify = (e) => {
    const username = window.signupEmail
    confirmSignUp(username, verifyCode)
    e.preventDefault()
    return false
  }

  return (
    <div className="flex flex-col">
      <h1 className="text-center">Register owner verify</h1>
      <input
        className="inp"
        value={verifyCode}
        onChange={inputChange}
        placeholder="Enter Verify Code "
      />
      <a
        href="/register/owner/verified"
        onClick={verify}
        className="w-9/12 mt-8 btn"
      >
        Verify
      </a>
    </div>
  )
}
