import React, { useState } from 'react'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { createRecord } from './graphql/mutations'
import keypair from 'keypair'

async function signUp(data) {
  try {
    window.signupEmail = data.email
    const user = await Auth.signUp({
      username: data.email,
      password: '123456',
      attributes: {
        email: data.email,
        'custom:data': JSON.stringify(data),
      },
    })
    console.log({ user })
    window.router.setView(window.router.views.get('registerOperatorNFC'))
  } catch (error) {
    window.alert(error.message)
  }
}

const addRecord = (formData) => {
  const key = keypair({ bits: 256 })
  API.graphql(
    graphqlOperation(createRecord, {
      input: {
        id: `operator_${formData.email}`,
        data: JSON.stringify({ ...formData, key }),
      },
    })
  )

  API.graphql(
    graphqlOperation(createRecord, {
      input: {
        id: `operator_${formData.email}_key_private`,
        data: JSON.stringify(key.private),
      },
    })
  )

  API.graphql(
    graphqlOperation(createRecord, {
      input: {
        id: `operator_${formData.email}_key_public`,
        data: JSON.stringify(key.public),
      },
    })
  )
}

export default () => {
  const [formData, setFormData] = useState({})

  const inputChange = (key, value) => setFormData({ ...formData, [key]: value })

  const register = (e) => {
    signUp(formData)
    addRecord(formData)
    e.preventDefault()
    return false
  }

  return (
    <div className="flex flex-col">
      <h1>Register operator</h1>
      <input
        onChange={(event) => inputChange('lastName', event.target.value)}
        placeholder="Last Name"
      />
      <input
        onChange={(event) => inputChange('firstName', event.target.value)}
        placeholder="First Name"
      />
      <input
        onChange={(event) => inputChange('location', event.target.value)}
        placeholder="Location Name"
      />
      <input
        onChange={(event) => inputChange('role', event.target.value)}
        placeholder="Role"
      />
      <input
        onChange={(event) => inputChange('email', event.target.value)}
        placeholder="Verification Email"
      />
      <a
        href="/register/operator/nfc"
        onClick={register}
        className="w-9/12 mt-8 btn"
      >
        Register
      </a>
    </div>
  )
}
