import React from 'react'
import { observer } from 'mobx-react'
import { store } from './index'
import OrigeenCube from './OrigeenCube.svg'

const palette = {
  green: '#2bb538',
  yellow: '#fff727',
  red: '#ff2727',
}

const SvgX = () => (
  <svg
    style={{
      width: '26px',
      fill: palette.red,
      display: 'inline',
    }}
    viewBox="0 0 93.93 93.93">
    <path d="M80.18 13.76a46.97 46.97 0 10-66.42 66.42 46.97 46.97 0 0066.42-66.42zm-8.6 47.98l-9.84 9.83-14.77-14.76L32.2 71.58l-9.84-9.84 14.77-14.77L22.36 32.2l9.84-9.84 14.77 14.77 14.77-14.77 9.84 9.83L56.8 46.98l14.77 14.77z" />
  </svg>
)
const SvgCheck = () => (
  <svg
    style={{
      width: '26px',
      fill: palette.green,
      display: 'inline',
    }}
    viewBox="0 0 490.05 490.05">
    <path d="M418.27 418.27c95.7-95.7 95.7-250.8 0-346.5s-250.8-95.7-346.5 0-95.7 250.8 0 346.5 250.9 95.7 346.5 0zm-261.1-210.7l55.1 55.1 120.7-120.6 42.7 42.7-120.6 120.6-42.8 42.7-42.7-42.7-55.1-55.1 42.7-42.7z" />
  </svg>
)

const SvgIphone = ({ color }) => (
  <svg
    viewBox="0 0 56 56"
    style={{
      fill: palette[color],
      display: 'inline',
      width: '48px',
    }}>
    <path d="M39.6 0H17.4c-3 0-5.4 2.4-5.4 5.4V7a1 1 0 00-1 1v1c0 .6.4 1 1 1v1a1 1 0 00-1 1v1c0 .6.4 1 1 1v1a1 1 0 00-1 1v1c0 .6.4 1 1 1v32.6c0 3 2.4 5.4 5.4 5.4h22.2c3 0 5.4-2.4 5.4-5.4V5.4c0-3-2.4-5.4-5.4-5.4zM43 50.6c0 1.9-1.5 3.4-3.4 3.4H17.4a3.4 3.4 0 01-3.4-3.4V5.4C14 3.5 15.5 2 17.4 2h22.2C41.5 2 43 3.5 43 5.4v45.2z" />
    <path d="M29 6h3a1 1 0 100-2h-3a1 1 0 100 2zM25 6c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7s-.1-.5-.3-.7a1 1 0 00-1.4 0 1 1 0 00-.3.7c0 .3.1.5.3.7.2.2.4.3.7.3z" />
    <circle cx="28.5" cy="50.5" r="2.5" />
    <path d="M15 47h27V7H15v40zm16.3-35.7l1-1a1 1 0 111.4 1.4l-1 1a1 1 0 01-1.4 0 1 1 0 010-1.4zm-.6 2c.2.2.3.4.3.7s-.1.5-.3.7a1 1 0 01-.7.3 1 1 0 01-.7-.3 1 1 0 01-.3-.7c0-.3.1-.5.3-.7a1 1 0 011.4 0zm-3.4-3a1 1 0 111.4 1.4l-3 3a1 1 0 01-1.4 0 1 1 0 010-1.4l3-3zm-9 4l4-4a1 1 0 111.4 1.4l-4 4a1 1 0 01-1.4 0 1 1 0 010-1.4zM24 16c0 .3-.1.5-.3.7a1 1 0 01-.7.3 1 1 0 01-.7-.3c-.2-.2-.3-.4-.3-.7s.1-.5.3-.7a1 1 0 011.4 0c.2.2.3.4.3.7zm-5.7 3.3l2-2a1 1 0 111.4 1.4l-2 2a1 1 0 01-1.4 0 1 1 0 010-1.4zm0 5l9-9a1 1 0 111.4 1.4l-9 9a1 1 0 01-1.4 0 1 1 0 010-1.4z" />
  </svg>
)

const SvgPerson = ({ color }) => (
  <svg
    style={{
      fill: palette[color],
      display: 'inline',
      width: '48px',
    }}
    viewBox="0 0 579.7 579.7">
    <path d="M63.7 429v113a37.7 37.7 0 0037.7 37.7h377A37.7 37.7 0 00516 542V429a113 113 0 00-113-113.2h-9.7a10 10 0 00-9.6 9.2 94.3 94.3 0 01-187.7 0 10 10 0 00-9.6-9.2h-9.6A113 113 0 0063.7 429zM289.8 310c75 0 135.6-69.5 135.6-155S364.7 0 289.8 0 154.2 69.4 154.2 155 215 310 289.9 310zm0-155c35.4 0 65-18 74.2-42.3a96 96 0 0122.7 61.6 96.9 96.9 0 01-193.7 0c0-23.5 61.5-19.3 96.8-19.3z" />
  </svg>
)

const SvgData = ({ color }) => (
  <svg
    viewBox="0 0 497.4 497.4"
    style={{
      fill: palette[color],
      display: 'inline',
      width: '34px',
      margin: '0 3px',
    }}>
    <path d="M387 0H110C83 0 61 22 61 49v399c0 27 22 49 49 49h277c27 0 50-22 50-49V49c0-27-23-49-50-49zm15 448c0 8-6 15-15 15H110c-8 0-15-7-15-15V49c0-8 7-15 15-15h277c9 0 15 7 15 15v399z" />
    <path d="M176 146h111a17 17 0 000-34H176a17 17 0 000 34zM322 198H176a17 17 0 000 34h146a17 17 0 000-34zM322 283H176a17 17 0 100 35h146a17 17 0 000-35zM322 369H176a17 17 0 000 34h146a17 17 0 100-34z" />
  </svg>
)

const SvgQuestion = ({ color }) => (
  <svg
    viewBox="0 0 417 417"
    style={{
      fill: palette.yellow,
      display: 'inline',
      width: '27px',
    }}>
    <path d="M356 61A208 208 0 1061 356 208 208 0 00356 61zM209 335a20 20 0 110-40 20 20 0 010 40zm32-120c-11 6-12 14-12 38v5c-1 11-10 20-21 20s-20-9-20-20v-5c0-24 0-55 33-73 26-16 29-26 26-39-4-15-18-19-29-18-4 0-22 4-22 22a20 20 0 01-41 0c0-33 24-58 59-62s65 16 72 49c12 49-29 73-45 83z" />
  </svg>
)

const SvgPin = ({ color }) => (
  <svg
    viewBox="0 0 466.6 466.6"
    style={{
      fill: '#212866',
      display: 'inline',
      width: '17px',
      position: 'relative',
      top: '-3px',
      marginRight: '7px',
    }}>
    <path d="M233 0C148 0 79 69 79 154c0 35 22 90 67 171 32 57 63 105 64 107l23 35 23-35c2-2 33-50 65-107 45-81 67-136 67-171C388 69 318 0 233 0zm0 233a80 80 0 110-160 80 80 0 010 160z" />
  </svg>
)

const LabelData = ({ code: [gtin, expiry, batch, sn, signature], owner }) => (
  <div
    className="flex bg-white mx-auto flex-col text-left -mt-12 px-4 py-3"
    style={{ color: '#212866', fontSize: '90%' }}>
    {/*  <img alt="" src={OrigeenCube} className="w-1/3 m-auto" />
    <div className="font-bold m-auto text-2xl mt-6">Xonetix Cube</div>
    <hr
      className=" border-solid border-2 mt-2"
      style={{ borderColor: '#212866' }}
    /> */}
    <div>
      <b>GTIN:</b> {gtin}
      <br />
      <b>Expiry:</b> {expiry}
      <br />
      <b>Batch/lot:</b> {batch}
      <br />
      <b>S/N:</b> {sn}
      <br />
      <br />
      <b>Signature:</b>
      <br />
      {signature}
    </div>
    <hr
      className=" border-solid border-2 mt-2"
      style={{ borderColor: '#212866' }}
    />
    <div className="font-bold pt-2 text-center">
      <SvgPin />
      {owner}
    </div>
  </div>
)

const overlayStyle = {
  position: 'relative',
  left: '-28px',
  color: '#212866',
  fontWeight: 'bold',
}

const Checks = ({ ok: { owner, device }, ownerNum, deviceNum }) => (
  <div className="w-full mt-4">
    {/* <div>
      <SvgData color={data} />
      <span className="pl-2">{data === 'green' ? <SvgCheck /> : <SvgX />}</span>
    </div> */}
    <div className="pt-3">
      <SvgPerson color={owner} />
      <span style={{ ...overlayStyle, top: '18px' }}>{ownerNum}</span>
      <span className="pl-2">
        {owner === 'green' ? (
          <SvgCheck />
        ) : owner === 'yellow' ? (
          <SvgQuestion />
        ) : (
          <SvgX />
        )}
      </span>
    </div>
    <div className="pt-3">
      <SvgIphone color={device} />
      <span style={{ ...overlayStyle, top: '9px' }}>{deviceNum}</span>
      <span className="pl-2">
        {device === 'green' ? (
          <SvgCheck />
        ) : device === 'yellow' ? (
          <SvgQuestion />
        ) : (
          <SvgX />
        )}{' '}
      </span>
    </div>
  </div>
)

export default observer(() => {
  if (!store.verifyCode) return null

  let code = store.verifyCode.split('-')
  code = code.map((c, index) => {
    if (index < 4) return ['(', c.slice(0, 2), ')', c.slice(2)].join('')
    return c
  })

  let warning = 'Wrong hash/signature'
  let okd = { data: 'red', owner: 'red', device: 'red' }
  let owner = 'Unknown owner'
  let user = '?'
  let device = '?'
  let showlabel = false

  console.log(code)

  if (code[4] === '16dc8b8da3a3846561f95ed8b11e9b80') {
    showlabel = true
    warning = ''
    owner = 'Xonetix Plant BC Switzerland'
    okd.data = 'green'
    okd.owner = 'green'
    okd.device = 'green'
    user = '1'
    device = '1'
  }

  if (code[4] === '84fcf4f2c88ba06d681d8e12e26cd4b2') {
    showlabel = true
    warning = ''
    owner = 'Xonetix Plant AX Switzerland'
    okd.data = 'green'
    okd.owner = 'green'
    okd.device = 'green'
    user = '2'
    device = '2'
  }

  if (code[4] === '212e59a3338a800f569e7ba65283ef86') {
    showlabel = true
    warning = 'Deviation at owner'
    owner = 'Xonetix Plant AX Switzerland'
    okd.owner = 'yellow'
    okd.device = 'green'
    user = '1'
    device = '2'
  }

  if (code[4] === '80d2bae61cdf240313f8bcb794719c5f') {
    showlabel = true
    warning = 'Deviation at device'
    owner = 'Xonetix Plant BC Switzerland'
    okd.owner = 'green'
    okd.device = 'yellow'
    user = '1'
    device = '2'
  }

  // if (code[4] === 'cf68270b8540ba81f95ad4b1301329a1') {
  //   demo = 'Wrong hash/signature'
  //   okd.device = 'red'
  //   okd.owner = 'red'
  //   okd.data = 'red'
  // }

  return (
    <div className="flex w-full flex-col">
      <div className="fit-content m-auto pt-3">
        {showlabel && <LabelData code={code} owner={owner} />}
        {warning && (
          <div
            className="flex bg-white mx-auto width-100 flex-col p-4 mt-2 text-lg font-bold"
            style={{ color: '#212866' }}>
            {warning}
          </div>
        )}
      </div>
      <Checks ok={okd} ownerNum={user} deviceNum={device} />
    </div>
  )
})
