import React from 'react'

export default () => {
  return (
    <div className="flex flex-col">
      <h1 className="text-center">Operator verified</h1>

      <a
        href="/"
        onClick={(e) => {
          window.router.setView(window.router.views.get('home'))
          e.preventDefault()
          return false
        }}
        target="_self"
        className="mt-8 btn"
      >
        Finish
      </a>
    </div>
  )
}
