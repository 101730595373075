import React from 'react'
import QRCode from 'qrcode.react'

export default ({ code, value }) => (
  <div className="flex bg-white text-black mx-auto" style={{ width: '400pt' }}>
    <QRCode className="block" includeMargin renderAs={'svg'} value={value} />
    <div className="flex flex-col text-left text-blue-700 pl-1 print:text-black">
      <div className="leading-7 mt-2">
        <span className="inline-block w-24 font-bold">GTIN:</span>
        {code[0]}
      </div>
      <div className="leading-7">
        <span className="inline-block w-24 font-bold">Expiry:</span>
        {code[1]}
      </div>
      <div className="leading-7">
        <span className="inline-block w-24 font-bold">Batch/lot:</span>
        {code[2]}
      </div>
      <div className="leading-7">
        <span className="inline-block w-24 font-bold">S/N:</span>
        {code[3]}
      </div>
      {/* <div className="text-green-500 leading-5 print:text-black">
        <span className="inline-block w-24  font-bold">Signature:</span>
        {code[4]}
      </div> */}
    </div>
  </div>
)
