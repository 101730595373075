import React, { useEffect, useState } from 'react'
import QrReader from 'react-qr-scanner'

const Qr = () => {
  const [results, setResults] = useState([])
  const [err, setErr] = useState('')

  const handleScan = (newResult) =>
    setResults((prevResult) => [...prevResult, newResult])

  const handleError = (err) => setErr(err)

  const previewStyle = {
    height: 240,
    width: 320,
  }

  return (
    <div>
      <QrReader
        delay={100}
        style={previewStyle}
        onError={handleError}
        onScan={handleScan}
      />
      <ul>
        {results.map((result, index) => (
          <li key={index}>
            <a href={result} className="text-blue-800 underline" target="_new">
              {result}
            </a>
          </li>
        ))}
      </ul>
      {err && <p className="text-red-600">{JSON.stringify(err)}</p>}
    </div>
  )
}

export default () => {
  const [nfc, setNfc] = useState(
    'Please scan your personal NFC or RFID card now'
  )

  useEffect(() => {
    try {
      const reader = new window.NDEFReader()
      reader.scan()

      reader.addEventListener('error', (error) => {
        window.alert(`NFC read error: ${JSON.stringify(error)}`)
      })

      reader.addEventListener('reading', ({ message, serialNumber }) =>
        setNfc(serialNumber + JSON.stringify(message))
      )
    } catch (error) {
      window.alert('NFC start error: ' + JSON.stringify(error))
    }
  })

  return (
    <div className="flex flex-col">
      <h1 className="text-center">Operator NFC/RFID</h1>
      <div className="w-9/12 px-10 py-4 mx-auto text-xl text-left text-center bg-transparent border-4 border-gray-500">
        {nfc}
      </div>
      <a
        href="/register/operator/verify"
        onClick={(e) => {
          window.router.setView(
            window.router.views.get('registerOperatorVerify')
          )
          e.preventDefault()
          return false
        }}
        target="_self"
        className="mt-8 btn"
      >
        next step
      </a>
      <Qr />
    </div>
  )
}
