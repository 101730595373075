import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import './tailwind.css'
import Register from './Register'
import * as serviceWorker from './serviceWorker'
import Amplify, { Hub, API, graphqlOperation } from 'aws-amplify'
import { onCreateRecord, onUpdateRecord } from './graphql/subscriptions'
import { Auth } from '@aws-amplify/auth'

import {
  Authenticator,
  SignIn,
  SignUp,
  ConfirmSignUp,
  Greetings,
} from 'aws-amplify-react'

import awsconfig from './aws-exports'
import RegisterOwner from './RegisterOwner'
import RegisterDevice from './RegisterDevice'
import RegisterOwnerVerify from './RegisterOwnerVerify'
import RegisterOwnerVerified from './RegisterOwnerVerified'
import RegisterOperator from './RegisterOperator'
import RegisterOperatorVerify from './RegisterOperatorVerify'
import RegisterOperatorVerified from './RegisterOperatorVerified'
import RegisterOperatorNFC from './RegisterOperatorNFC'
import OperatorProfile from './OperatorProfile'
import LoginNFC from './LoginNFC'

import {
  RouterStore,
  View,
  startRouter,
  StateRouter,
} from 'mobx-state-tree-router'

import { types } from 'mobx-state-tree'
import Header from './Header'
import Verify from './Verify'
import QrScanner from './QrScanner'
import OperatorBatch from './OperatorBatch'
import { randHash } from './util'

import 'toastify-js/src/toastify.css'
import Toastify from 'toastify-js'

const { model, map, string } = types

Hub.listen('auth', (e) => {
  console.log(e.payload)

  if (e.payload.event === 'signIn') {
    Toastify({
      text: `You have successfully logged in as ${e.payload.data.attributes.email}`,
      duration: 5000,
    }).showToast()
  }
})

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

const User = model({
  name: string,
})

const RootStore = model({
  users: map(User),
  verifyCode: '',
}).actions((root) => ({
  addUser(name) {
    root.users.set(name, { name })
  },
  getUser(name) {
    return root.users.get(name)
  },
  set(field, value) {
    root[field] = value
  },
}))

export const store = RootStore.create()
window.store = store

const Foo = () => {
  useEffect(() => {
    const subscriber = API.graphql(graphqlOperation(onUpdateRecord)).subscribe({
      next: (data) => {
        console.dir(data)
        console.table(data)
      },
    })
    return () => subscriber.unsubscribe()
  }, [])

  useEffect(() => {
    const subscriber = API.graphql(graphqlOperation(onCreateRecord)).subscribe({
      next: (data) => {
        console.dir(data)
        console.table(data)
      },
    })
    return () => subscriber.unsubscribe()
  }, [])

  return (
    <>
      <div className="flex flex-col">
        <a
          href="/register"
          className="btn"
          onClick={(e) => {
            router.setView(router.views.get('register'))
            e.preventDefault()
            return false
          }}>
          Register
        </a>
        <a
          href="/scan"
          className="btn"
          onClick={(e) => {
            router.setView(router.views.get('scan'))
            e.preventDefault()
            return false
          }}>
          Scan QR code
        </a>
        <a
          href="/operator"
          className="btn"
          onClick={(e) => {
            router.setView(router.views.get('operator'))
            e.preventDefault()
            return false
          }}>
          Operator profile
        </a>
        <a
          href={
            '/' +
            ((Math.random() * 99999999) | 0) +
            ((Math.random() * 99999999) | 0) +
            ((Math.random() * 9999) | 0) +
            '-' +
            ((Math.random() * 99999999) | 0) +
            '-00TEST' +
            ((Math.random() * 99999) | 0) +
            '-' +
            ((Math.random() * 99999999) | 0) +
            ((Math.random() * 99999999) | 0) +
            '-' +
            '84fcf4f2c88ba06d681d8e12e26cd4b2'
          }
          className="btn">
          Verify QR demo
        </a>
      </div>
    </>
  )
}

const Login = () => (
  <>
    <Authenticator hideDefault={true}>
      <SignIn />
      <SignUp />
      <ConfirmSignUp />
      <Greetings />
    </Authenticator>

    <div className="pt-3 mt-3 border-t-2 border-gray-700">
      default username: confirmed email
    </div>
    <div>default password: 123456</div>
    <a
      href="/login/nfc"
      target="_self"
      className="block mt-8 btn"
      onClick={(e) => {
        // eslint-disable-next-line no-undef
        const reader = new NDEFReader()
        reader.scan()
        // window.router.setView(window.router.views.get('loginNfc'))
        e.preventDefault()
        return false
      }}>
      NFC
    </a>

    <a href="/login/nfc" target="_self" className="block mt-8 btn">
      NFC login
    </a>
  </>
)

const views = {
  home: View.create({
    name: 'home',
    path: '/',
    component: <Foo />,
  }),
  register: View.create({
    name: 'register',
    path: '/register',
    component: <Register />,
  }),
  registerOwner: View.create({
    name: 'registerOwner',
    path: '/register/owner',
    component: <RegisterOwner />,
  }),
  registerDevice: View.create({
    name: 'registerDevice',
    path: '/register/device',
    component: <RegisterDevice />,
  }),
  registerOwnerVerify: View.create({
    name: 'registerOwnerVerify',
    path: '/register/owner/verify',
    component: <RegisterOwnerVerify />,
  }),
  registerOwnerVerified: View.create({
    name: 'registerOwnerVerified',
    path: '/register/owner/verified',
    component: <RegisterOwnerVerified />,
  }),
  registerOperator: View.create({
    name: 'registerOperator',
    path: '/register/operator',
    component: <RegisterOperator />,
  }),
  operator: View.create({
    name: 'operator',
    path: '/operator',
    component: <OperatorProfile />,
  }),
  operatorBatch: View.create({
    name: 'operatorBatch',
    path: '/operator/batch',
    component: <OperatorBatch />,
  }),
  registerOperatorVerify: View.create({
    name: 'registerOperatorVerify',
    path: '/register/operator/verify',
    component: <RegisterOperatorVerify />,
  }),
  registerOperatorNFC: View.create({
    name: 'registerOperatorNFC',
    path: '/register/operator/nfc',
    component: <RegisterOperatorNFC />,
  }),
  registerOperatorVerified: View.create({
    name: 'registerOperatorVerified',
    path: '/register/operator/verified',
    component: <RegisterOperatorVerified />,
  }),
  login: View.create({
    name: 'login',
    path: '/login',
    component: <Login />,
  }),
  loginNFC: View.create({
    name: 'loginNFC',
    path: '/login/nfc',
    component: <LoginNFC />,
  }),
  scan: View.create({
    name: 'scan',
    path: '/scan',
    component: <QrScanner />,
  }),
  verify: View.create({
    name: 'verify',
    path: '/:code',
    component: <Verify />,
    hooks: {
      beforeEnter(_, params) {
        store.set('verifyCode', params.code)
      },
    },
  }),
}

export const router = RouterStore.create({ views })
window.router = router

startRouter(router)

// window.LOG_LEVEL = 'DEBUG'

ReactDOM.render(
  <React.StrictMode>
    <div className="print:bg-white print:min-h-screen">
      <Header />
      <StateRouter router={router} />
      <a
        href="/login"
        onClick={(e) => {
          e.preventDefault()
          window.router.setView(window.router.views.get('login'))
          return false
        }}
        className="block mt-12 text-xs underline print:hidden">
        account
      </a>
    </div>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.register()
