import React, { useState } from 'react'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { createRecord } from './graphql/mutations'
import keypair from 'keypair'

async function signUp(data) {
  try {
    window.signupEmail = data.email
    const user = await Auth.signUp({
      username: data.email,
      password: '123456',
      attributes: {
        email: data.email,
        'custom:data': JSON.stringify(data),
      },
    })
    console.log({ user })
    //window.router.setView(window.router.views.get('registerDeviceVerify'))
  } catch (error) {
    window.alert(error.message)
  }
}

function Register() {
  const [formData, setFormData] = useState({})

  const inputChange = (key, value) => setFormData({ ...formData, [key]: value })

  const register = (e) => {
    signUp(formData)
    addRecord(formData)
    e.preventDefault()
    return false
  }

  const addRecord = () => {
    const key = keypair({ bits: 256 })

    API.graphql(
      graphqlOperation(createRecord, {
        input: {
          id: `device_${formData.email}`,
          data: JSON.stringify({ ...formData, key }),
        },
      })
    )

    API.graphql(
      graphqlOperation(createRecord, {
        input: {
          id: `device_${formData.email}_key_private`,
          data: JSON.stringify(key.private),
        },
      })
    )

    API.graphql(
      graphqlOperation(createRecord, {
        input: {
          id: `device_${formData.email}_key_public`,
          data: JSON.stringify(key.public),
        },
      })
    )
  }

  return (
    <div className="flex flex-col">
      <h1>Register device</h1>
      <input
        onChange={(event) => inputChange('company', event.target.value)}
        placeholder="Company Name"
      />
      <input
        onChange={(event) => inputChange('gln', event.target.value)}
        placeholder="GLN"
      />
      <input
        onChange={(event) => inputChange('location', event.target.value)}
        placeholder="Location Name"
      />
      <input
        onChange={(event) => inputChange('role', event.target.value)}
        placeholder="Role"
      />
      <a
        href="/register/device/verify"
        onClick={register}
        className="w-9/12 mt-8 btn">
        Register
      </a>
    </div>
  )
}

export default Register
