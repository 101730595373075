import React, { useState, useEffect } from 'react'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { getRecord } from './graphql/queries'
import { router } from './index'

// import { DataStore } from '@aws-amplify/datastore'

// import keypair from 'keypair'
// console.log(Auth)
// window.Auth = Auth

export default () => {
  const [email, setEmail] = useState('')
  const [formData, setFormData] = useState({})
  const [privateKey, setPrivateKey] = useState('')
  const [publicKey, setPublicKey] = useState('')

  // const inputChange = (key, value) => setFormData({ ...formData, [key]: value })

  useEffect(() => {
    const load = async () => {
      const u = await Auth.currentUserInfo()
      if (!u) return
      const { email } = u.attributes
      setEmail(email)
    }
    load()
  }, [])

  useEffect(() => {
    const load = async () => {
      if (!email) return
      const re = await API.graphql(
        graphqlOperation(getRecord, { id: `operator_${email}` })
      )

      if (!re.data.getRecord) return

      const data = JSON.parse(re.data.getRecord.data)
      setFormData(data)
    }
    load()
  }, [email])

  useEffect(() => {
    const load = async () => {
      if (!email) return

      const re = await API.graphql(
        graphqlOperation(getRecord, { id: `operator_${email}_key_private` })
      )

      if (!re.data.getRecord) return

      const data = JSON.parse(re.data.getRecord.data)
      setPrivateKey(data)
    }
    load()
  }, [email])

  useEffect(() => {
    const load = async () => {
      if (!email) return

      const re = await API.graphql(
        graphqlOperation(getRecord, { id: `operator_${email}_key_public` })
      )

      if (!re.data.getRecord) return

      const data = JSON.parse(re.data.getRecord.data)
      setPublicKey(data)
    }
    load()
  }, [email])

  if (!email) {
    return (
      <div className="flex flex-col">
        <h1>Operator profile</h1>
        please login
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <h1>Operator profile</h1>
      <input
        // onChange={(event) => inputChange('lastName', event.target.value)}
        placeholder="Last Name"
        value={formData.lastName}
        readOnly
      />
      <input
        // onChange={(event) => inputChange('firstName', event.target.value)}
        value={formData.firstName}
        placeholder="First Name"
        readOnly
      />
      <input
        // onChange={(event) => inputChange('location', event.target.value)}
        value={formData.locationName}
        placeholder="Location Name"
        readOnly
      />
      <input
        // onChange={(event) => inputChange('role', event.target.value)}
        value={formData.role}
        placeholder="Role"
        readOnly
      />
      <input
        // onChange={(event) => inputChange('email', event.target.value)}
        value={formData.email}
        placeholder="Verification Email"
        readOnly
      />
      <input value={privateKey} placeholder="Private key" readOnly />
      <input value={publicKey} placeholder="Public key" readOnly />
      {/* <button onClick={saveChanges} className="w-9/12 mt-8 btn">
        Save changes
  </button>*/}
      <a
        href="/operator/batch"
        className="btn"
        onClick={(e) => {
          router.setView(router.views.get('operatorBatch'))
          e.preventDefault()
          return false
        }}
      >
        Prepare batch
      </a>
    </div>
  )
}
