import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { router } from './index'
import QrLabel from './QrLabel'
import { randHash } from './util'
import Toastify from 'toastify-js'

const Item = ({ index }) => {
  const code = [
    '(24)03762260402757989',
    '(28)900203',
    '(00)1525187',
    `(76)49726420620986${typeof index === 'string' ? '3' : index}`,
    typeof index === 'string' ? index : randHash(),
  ]
  return (
    <QrLabel
      code={code}
      value={window.location.origin + '/' + code.join('-').replace(/[()]/g, '')}
    />
  )
}

export default () => {
  const [email, setEmail] = useState('')
  const [labels, setLabels] = useState([1])
  const [batchName, setBatchName] = useState('Batch of one label')

  useEffect(() => {
    const load = async () => {
      const u = await Auth.currentUserInfo()
      if (!u) return
      const { email } = u.attributes
      setEmail(email)
    }
    load()
  }, [])

  return (
    <div className="flex flex-col">
      <h1 className="print:hidden">Operator batch</h1>
      <select
        className="p-2 mb-2 text-black border-none rounded-md print:hidden"
        onChange={(e) => {
          setLabels(JSON.parse(e.target.value))

          const index = e.nativeEvent.target.selectedIndex
          const label = e.nativeEvent.target[index].text
          setBatchName(label)
        }}>
        <option value="[1]">Batch of one random label</option>
        <option value="[8, 3, 2, 9]">Batch of four random labels</option>
        <option value='["16dc8b8da3a3846561f95ed8b11e9b80","84fcf4f2c88ba06d681d8e12e26cd4b2","212e59a3338a800f569e7ba65283ef86","80d2bae61cdf240313f8bcb794719c5f","cf68270b8540ba81f95ad4b1301329a1"]'>
          Batch of five magic labels
        </option>
        <option value="[1, 5, 4, 7, 2, 9, 3]">
          Batch of seven random labels
        </option>
      </select>
      <div className="w-full mx-auto overflow-hidden text-xs leading-8 bg-black bg-opacity-25 border-2 border-gray-600 print:text-base print:bg-transparent print:border-none print:text-black">
        {labels.map((element) => (
          <Item index={element} key={element} />
        ))}
      </div>
      <div className="mt-4 text-xs print:hidden">
        * To print these labels read the NFC on printer while logged in with{' '}
        {email} account.
      </div>
      <button
        className="mt-16 btn print:hidden"
        onClick={() => {
          Toastify({
            text: `Printing batch "${batchName}"`,
            duration: 3000,
            callback: () => {
              window.print()
              labels.forEach((label) => {
                setTimeout(
                  () =>
                    Toastify({
                      text: `Added S/N (76)4972642062098${label} to blockchain`,
                      duration: 3000,
                    }).showToast(),
                  Math.random() * 1000
                )
              })
            },
          }).showToast()
        }}>
        Print batch
      </button>
      <a
        href="/operator"
        className="mt-16 btn print:hidden"
        onClick={(e) => {
          router.setView(router.views.get('operator'))
          e.preventDefault()
          return false
        }}>
        return to profile
      </a>
    </div>
  )
}
