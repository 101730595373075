/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_content_delivery_bucket": "origeen-dev",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://d3bgor6o1kehf.cloudfront.net",
    "aws_cognito_identity_pool_id": "eu-central-1:4f1147fb-2680-4ec9-9b3d-f53ee9553896",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_80SftrSFP",
    "aws_user_pools_web_client_id": "vrvndg1hh5v37amfeigpctugm",
    "oauth": {
        "domain": "origeenf1b6e3f9-f1b6e3f9-dev.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://origeen.io/",
        "redirectSignOut": "https://origeen.io/",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_appsync_graphqlEndpoint": "https://ia5g4m7suvgvre76ibhutdfira.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
