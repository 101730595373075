import React, { useEffect, useState } from 'react'
import { router } from './index'

export default () => {
  const [nfc, setNfc] = useState(
    'Please scan your personal NFC or RFID card now'
  )

  useEffect(() => {
    try {
      const reader = new window.NDEFReader()
      reader.scan()

      reader.addEventListener('error', (error) => {
        window.alert(`NFC read error: ${JSON.stringify(error)}`)
      })

      reader.addEventListener('reading', ({ message, serialNumber }) => {
        setNfc(serialNumber + JSON.stringify(message))
        setTimeout(
          () => router.setView(router.views.get('operatorBatch')),
          1000
        )
      })
    } catch (error) {
      window.alert('NFC start error: ' + JSON.stringify(error))
    }
  })

  return (
    <div className="flex flex-col">
      <h1 className="text-center">Operator NFC/RFID</h1>
      <div className="w-9/12 px-10 py-4 mx-auto text-xl text-center bg-transparent border-4 border-gray-500">
        {nfc}
      </div>
      <a
        href="/login"
        onClick={(e) => {
          e.preventDefault()
          router.setView(router.views.get('login'))
          return false
        }}
        target="_self"
        className="mt-8 btn"
      >
        return to login
      </a>
    </div>
  )
}
