/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRecord = /* GraphQL */ `
  mutation CreateRecord($input: CreateRecordInput!) {
    createRecord(input: $input) {
      id
      data
    }
  }
`;
export const updateRecord = /* GraphQL */ `
  mutation UpdateRecord($input: UpdateRecordInput!) {
    updateRecord(input: $input) {
      id
      data
    }
  }
`;
export const deleteRecord = /* GraphQL */ `
  mutation DeleteRecord($input: DeleteRecordInput!) {
    deleteRecord(input: $input) {
      id
      data
    }
  }
`;
