import React from 'react'
import QrReader from 'react-qr-reader'

export default () => {
  const [data, setData] = React.useState('https://origeen.io')
  const handleError = (err) => console.log(err)
  const handleScan = (data) => {
    if (data) setData(data)
  }

  return (
    <div>
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%' }}
      />
      <a href={data}>
        <br />
        {data}
      </a>
      <br />
      <br />
      <a
        href="/"
        onClick={(e) => {
          window.router.setView(window.router.views.get('home'))
          e.preventDefault()
          return false
        }}
        target="_self"
        className="mt-8 btn p-2">
        Home
      </a>
    </div>
  )
}
