import React from 'react'

export default () => {
  return (
    <div className="flex flex-col">
      <h1 className="text-center">Owner verified</h1>
      <div className="w-9/12 px-6 py-4 mx-auto text-xl text-left bg-transparent border-2 border-gray-800">
        Your private key was generated and stored in your Origeen wallet.
      </div>
      <a
        href="/"
        onClick={(e) => {
          window.router.setView(window.router.views.get('home'))
          e.preventDefault()
          return false
        }}
        target="_self"
        className="mt-8 btn"
      >
        Finish
      </a>
    </div>
  )
}
